import './assets/tailwind.css'
import './assets/base.css'
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import i18n from './common/i18n';

const app = createApp(App)
i18n(app)
app.use(createPinia())
app.use(router)
app.use(Antd).mount('#app');

// app.mount('#app')
