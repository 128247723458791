<script setup lang="ts">
import { RouterView } from 'vue-router'
import { onMounted, onUnmounted, ref } from 'vue'
import SigninModel from './components/signin/SigninModel.vue'
import SignInButton from './components/signin/UserInfo.vue'
import i18next from 'i18next';
import GlobalFooter from '@/components/GlobalFooter.vue'

var innerWidth = ref(window.innerWidth);
const currentLanguage = ref(i18next.resolvedLanguage);

onMounted(() => {
  document.body.style.backgroundColor = 'black';
  window.addEventListener('resize', updateInnerWidth);
  i18next.on('languageChanged', (lng) => {
    currentLanguage.value = lng;
  });
})

onUnmounted(() => {
  window.removeEventListener('resize', updateInnerWidth);
  i18next.off('languageChanged');
})

const updateInnerWidth = () => {
  innerWidth.value = window.innerWidth;
  const rootFontSize = window.innerWidth / 100; // 例如，视口宽度的 1/100
  document.documentElement.style.fontSize = `${rootFontSize}px`;
}

const changedLanguage = (language: string) => {
  const currentLanguage = i18next.language;
  console.log('currentLanguage:', currentLanguage);
  i18next.changeLanguage(currentLanguage === 'en' ? 'zh' : 'en');
}

</script>

<template>
  <div id="app">
    <header>
      <!-- <img alt="Mur logo" class="logo" src="@/assets/logo.png" width="60" height="60" /> -->

      <div class="wrapper">
        <RouterLink class="router-link" exact-active-class="" to="/">
          {{ i18next.t('downloadAPP') }}
        </RouterLink>
        <RouterLink class="router-link" exact-active-class="" to="/about">
          <img alt="Contact us" class="tab_icon" src="@/assets/contact-us.svg"/>
          {{ i18next.t('contactUs') }}
        </RouterLink>
        <SignInButton />
        <a-button 
          @click="changedLanguage" 
          type="primary"
          size="small"
          style="width: 60px; background-color: var(--vt-c-app_theme);"
        >
          {{ currentLanguage === 'en' ? '中文' : 'English' }}
       </a-button>
      </div>
    </header>
    
    <RouterView v-slot="{ Component }" class="router-view" >
       <keep-alive :exclude="'ChatPage'">
        <component :is="Component" />
      </keep-alive>
    </RouterView>
    <!-- <GlobalFooter /> -->
    <!-- <nav class="bottomTabBars">
      <RouterLink class="router-link-bottom"  exact-active-class="" to="/" >Home</RouterLink>
      <RouterLink class="router-link-bottom"  exact-active-class="" to="/downApp">Chat</RouterLink>
      <RouterLink class="router-link-bottom"  exact-active-class="" to="/about">Me</RouterLink>
    </nav> -->
      
  </div>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
  background-color: rgba(255, 255, 255, 0.1); /* 半透明白色 */
  backdrop-filter: blur(2px); /* 模糊半径为10px */
}

header .wrapper {
    display: flex;
    /* place-items: center; */
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    /* padding-right: 350px; */
    /* padding-left: 350px; */
  }

.logo {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}
.explore-icon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 5px;
}

nav {
  width: 100%;
  font-size: 12px;
}
.bottomTabBars {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color:black; /* 示例背景色 */
  padding: 10px 0;
  height: 50px;
  align-items: center;
}

.router-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tab_icon {
  margin-right: 5px;
}
.router-link-bottom {
  display: inline-block;
  color: var(--color-text);
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}
.router-view {
  padding-bottom: 64px;
}

.header-title-view {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  header {
    display: flex;
  }

  .logo {
    margin: 1rem 1rem 0.5rem 1rem;
  }

  header .wrapper {
    display: flex;
    /* place-items: center; */
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding-right: 18.2rem;
    padding-left: 18.2rem;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    /* margin-top: 1rem; */
  }
  .router-link-bottom {
    display: none;
  }
  .bottomTabBars {
    display: none;
  }
}
</style>
