<script setup lang="ts">
import { Button, Carousel } from 'ant-design-vue';

import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import TitleView from '@/components/TitleView.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useMurI18n } from '@/common/i18n';
import { getI18nObjc } from '../utils';

defineProps({
  bannerData: {
    type: Array<any>,
    required: true,
  },
});
const innerWidth = ref(window.innerWidth);

onMounted(() => {
  window.addEventListener('resize', updateInnerWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateInnerWidth);
});

const updateInnerWidth = () => {
  innerWidth.value = window.innerWidth;
};

const dynamicImageStyle = computed(() => {
  if (innerWidth.value < 768) {
    return {
      width: '100px',
    };
  }
  const width = 741 * innerWidth.value / 1920;
  return {
    width: `${width}px`,
  };
});

const clickedAppStore = () => {
  console.log('someEvent')
  const appStoreUrl = "https://apps.apple.com/app/apple-store/id6618151695";
  window.open(appStoreUrl, '_blank');

}

const clickedDownAPK = () => {
  const apkUrl = "https://download.murmured.me/app/app-release.apk";
  window.open(apkUrl, '_blank');

}

const clickedGooglePlay = () => {
  console.log('someEvent')
  const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.murmured";
  window.open(googlePlayUrl, '_blank');

  // emit('someEvent')
}
</script>
<template>
  <div class="carousel-container">
    <img 
    src="@/assets/download/banner_role.png"
    class="role-image"
    />
    <Carousel arrows class="carousel" :dots="false" >
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div v-for="data in bannerData" :key="data.id">
        <div class="title-view">
          <TitleView :msg="data.title" :description="data.description" />
        </div>
        <div class="download-buttons">
          <Button class="download-button" @click="clickedDownAPK">
            <img class="download-button-icon" src="@/assets/download/android_apk.svg" style="margin-right: 0.5rem"/> 
            <div class="button-text">
              <div class="button-subtitle">
                {{ getI18nObjc().downloadOn }}
              </div>
              <div class="button-title">APK</div>
            </div>
           
          </Button>
          <Button class="download-button" @click="clickedAppStore">
            <img class="download-button-icon" src="@/assets/download/apple_store.svg" style="margin-right: 0.5rem"/> 
            <div class="button-text">
              <div class="button-subtitle">
                {{ getI18nObjc().downloadOn }}
              </div>
              <div class="button-title">App Store</div>
            </div>
           
          </Button>
          <!-- <Button class="download-button" @click="clickedAppStore">
            <img class="download-button-icon" src="@/assets/download/google_play.svg" style="margin-right: 0.5rem"/> 
            <div class="button-text">
              <div class="button-subtitle">
                {{ getI18nObjc().downloadOn }}
              </div>
              <div class="button-title">Google Play</div>
            </div>
           
          </Button> -->
        </div>
      </div>
    </Carousel>
  </div>
</template>

<style scoped>
.carousel-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100vw;
    border-bottom: 1px solid #26272B;
    padding-bottom: 5vw;
  }
  .carousel{
    /* max-width: 1320px; */
    width: 100vw;
  }
  .title-view {
    display: flex;
    margin-top: 4vw;
    margin-left: 11vw;
    margin-right: 11vw;
  }
  /* For demo */
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    /* margin-top: 100px; */
    /* opacity: 0.3; */
  }
  .ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
  }
  .ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }

  .ant-carousel :deep(.slick-slide h3) {
    color: #fff;
  }
  .role-image {
    position: absolute;
    width: 350px;
    right: -150px;
    bottom: 0;

    /* margin-left: -541px; */
  }
  .download-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .download-button-icon {
    width: 30px;
    height: 30px;
  }
  .download-button {
    display: flex;
    width: 45vw;
    height: 15vw;
    background-color: #1A1B1ED0;
    color: #fff;
    border: none;
    border-radius: 2vw;
    margin-top: 5vw;
    margin-left: 3vw;
    /* font-size: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #26272B;
  }
  .button-text {
    display: flex;    
    flex-direction: column;
  }
  .button-subtitle {
    font-size: 10px;
    line-height: 10px;
    text-align: left;
  }
  .button-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
@media screen and (min-width: 768px){

  .carousel-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #26272B;
    padding-bottom: 6.875rem;
  }
  .carousel{
    max-width: 1320px;
    width: 68.75rem;
  }
  .title-view {
    display: flex;
    margin-top: 140px;
    margin-left: 60px;
    margin-right: 26rem;
  }
  /* For demo */
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    /* margin-top: 100px; */
    /* opacity: 0.3; */
  }
  .ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
  }
  .ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }

  .ant-carousel :deep(.slick-slide h3) {
    color: #fff;
  }
  .role-image {
    position: absolute;
    max-width: 741px;
    min-width: 20.5rem;
    width: 38.59rem;
    right: 6.19rem;
    bottom: 0;

    /* margin-left: -541px; */
  }
  .download-buttons {
    display: flex;
    flex-direction: row;
  }
  .download-button-icon {
    width: 1.67rem;
    height: 1.67rem;
  }
  .download-button {
    display: flex;
    width: 9.375rem;
    height: 3.125rem;
    background-color: #1A1B1E;
    color: #fff;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    margin-left: 60px;
    /* font-size: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #26272B;
  }
  .button-text {
    display: flex;    
    flex-direction: column;
  }
  .button-subtitle {
    font-size: 0.6rem;
    line-height: 0.6rem;
    text-align: left;
  }
  .button-title {
    font-size: 1.09rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}
</style>
