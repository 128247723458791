export function getUserID() {
    const userAccount = localStorage.getItem('user_auth_account');
    if (!userAccount) {
        return '';
    }
    const userAccountObj = JSON.parse(userAccount);
    return userAccountObj.userId;
}

export function getNsfwStatus() {
    const userProfile = localStorage.getItem('user_profile');
    if (!userProfile) {
        return false;
    }
    const userProfileObj = JSON.parse(userProfile);
    return userProfileObj.nsfw;
}