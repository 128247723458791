import { getUserProfile } from '@/components/signin/utils';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    userAvatar: '',
    userName: '',
    accessToken: '',
    userId: '',
    showSigninModal: false,
  }),
  actions: {
    setLoggedIn(status: boolean) {
      this.isLoggedIn = status;
      console.log('setLoggedIn', status);
    },
    setUserAvatar(avatar: string) {
      this.userAvatar = avatar;
    },
    setUserName(name: string) {
      this.userName = name;
    },
    setAccessToken(token: string) {
      this.accessToken = token;
    },
    setUserId(id: string) {
      this.userId = id;
    },
    setShowSigninModal(show: boolean) {
      console.log('setShowSigninModal', show);
      this.showSigninModal = show;
    },
    logout() {
      this.setLoggedIn(false);
      this.setUserAvatar('');
      this.setUserName('');
      this.setAccessToken('');
      this.setUserId('');
      Cookies.remove('user_auth_account');
      localStorage.removeItem('user_profile');
      console.log('User logged out');
    },
    async fetchUserProfile() {
      const userProfile = await getUserProfile();
      if (userProfile) {
        this.setUserAvatar(userProfile.image);
        this.setUserName(userProfile.name || userProfile.username);
      }
    },
  },
});