<template>
  <div class="email-signin">
    <a-form :model="formState" @finish="onFinish" @finishFailed="onFinishFailed">
      <a-form-item
        v-if="!isLogin"
        name="nickname"
        :rules="[{ required: true, message: JSON.parse(i18next.t('signInPage')).nicknameRequired }]"
      >
        <a-input v-model:value="formState.nickname" :placeholder="JSON.parse(i18next.t('signInPage')).nicknamePlaceholder">
          <template #prefix>
            <UserOutlined class="site-form-item-icon" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
        name="email"
        :rules="[
          { required: true, message: JSON.parse(i18next.t('signInPage')).emailRequired },
          { type: 'email', message: JSON.parse(i18next.t('signInPage')).invalidEmail }
        ]"
      >
        <a-input v-model:value="formState.email" :placeholder="JSON.parse(i18next.t('signInPage')).emailPlaceholder">
          <template #prefix>
            <MailOutlined class="site-form-item-icon" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
        v-if="!isLogin"
        name="emailCode"
        :rules="[{ required: true, message: JSON.parse(i18next.t('signInPage')).emailCodeRequired }]"
      >
        <div class="email-code-container">
          <a-input v-model:value="formState.emailCode" :placeholder="JSON.parse(i18next.t('signInPage')).emailCodePlaceholder">
            <template #prefix>
              <KeyOutlined class="site-form-item-icon" />
            </template>
          </a-input>
          <a-button 
            @click="sendEmailCode" 
            class="send-email-code-button"
            :disabled="!formState.email"
            type="primary"
            :loading="loading"
          >
            {{ JSON.parse(i18next.t('signInPage')).sendEmailCode }}
          </a-button>
        </div>
      </a-form-item>

      <a-form-item
        name="password"
        :rules="[{ required: true, validator: validatePassword }]"
      >
        <a-input-password
          v-model:value="formState.password"
          :placeholder="JSON.parse(i18next.t('signInPage')).passwordPlaceholder"
          :visibilityToggle="true"
        >
          <template #prefix>
            <LockOutlined class="site-form-item-icon" />
          </template>
        </a-input-password>
      </a-form-item>

      <a-form-item
        v-if="!isLogin"
        name="confirmPassword"
        :rules="[{ required: true, validator: validateConfirmPassword }]"
      >
        <a-input-password
          v-model:value="formState.confirmPassword"
          :placeholder="JSON.parse(i18next.t('signInPage')).confirmPasswordPlaceholder"
          :visibilityToggle="true"
        >
          <template #prefix>
            <LockOutlined class="site-form-item-icon" />
          </template>
        </a-input-password>
      </a-form-item>

      <a-form-item>
        <a-button 
          type="primary" 
          html-type="submit" 
          class="login-form-button"
        >
          {{ isLogin ? JSON.parse(i18next.t('signInPage')).signIn : JSON.parse(i18next.t('signInPage')).registerNow }}
        </a-button>
        <span class="or-text">{{ JSON.parse(i18next.t('signInPage')).or }}</span>
        <a @click="toggleMode" class="toggle-mode-link">{{ isLogin ? JSON.parse(i18next.t('signInPage')).registerNow : JSON.parse(i18next.t('signInPage')).loginNow }}</a>
      </a-form-item>
    </a-form>

    <div v-if="passwordStrength.value" class="password-strength">
      {{ JSON.parse(i18next.t('signInPage')).passwordStrength }}:
      <span :style="{ color: passwordStrength.color }">{{ JSON.parse(i18next.t('signInPage'))[passwordStrength.value] }}</span>
    </div>
    <a-button @click="$emit('back')" class="back-button">
      {{ JSON.parse(i18next.t('signInPage')).back }}
    </a-button>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import { Form, Input, Button, message } from 'ant-design-vue';
import { UserOutlined, LockOutlined, KeyOutlined, MailOutlined } from '@ant-design/icons-vue';
import { checkPasswordStrength, fetchEmailCode, loginWithEmail, registerWithEmail } from './utils';
import i18next from 'i18next';

const emit = defineEmits(['login-success', 'back']);
const loading = ref(false);
const isLogin = ref(true);
const formState = reactive({
  nickname: '',
  email: '',
  emailCode: '',
  password: '',
  confirmPassword: '',
});

const passwordStrength = reactive({
  value: '',
  color: '',
  valid: false,
});

watch(() => formState.password, (newPassword) => {
  const strength = checkPasswordStrength(newPassword);
  Object.assign(passwordStrength, strength);
});

const validatePassword = async (_rule: any, value: string) => {
  const strength = checkPasswordStrength(value);
  if (!strength.valid) {
    throw new Error(JSON.parse(i18next.t('signInPage')).passwordTooWeak);
  }
};

const validateConfirmPassword = async (_rule: any, value: string) => {
  if (value !== formState.password) {
    throw new Error(JSON.parse(i18next.t('signInPage')).passwordMismatch);
  }
};

const onFinish = async (values: any) => {
  try {
    if (isLogin.value) {
      const loginSuccess = await loginWithEmail(values.email, values.password);
      if (loginSuccess) {
        message.success(JSON.parse(i18next.t('signInPage')).loginSuccess);
        emit('login-success');
      }
    } else {
      await registerWithEmail(values.nickname, values.email, values.emailCode, values.password);
      message.success(JSON.parse(i18next.t('signInPage')).registrationSuccess);
      isLogin.value = true; // 注册成功后切换到登录模式
    }
  } catch (error: any) {
    console.log('Error:', error);
    message.error(error || JSON.parse(i18next.t('signInPage')).operationFailed);
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const toggleMode = () => {
  isLogin.value = !isLogin.value;
};

const sendEmailCode = () => {
  loading.value = true;
  if (formState.email.includes('@')) {
    fetchEmailCode(formState.email, formState.nickname).then(() => {
      loading.value = false;
      message.success(JSON.parse(i18next.t('signInPage')).emailCodeSentSuccess);
    }).catch((error) => {
      loading.value = false;
      message.error(JSON.parse(i18next.t('signInPage')).emailCodeSentError);
    });
  } else {
    message.error(JSON.parse(i18next.t('signInPage')).invalidEmail);
    loading.value = false;
  }
};
</script>

<style scoped>
.email-signin {
  max-width: 300px;
  margin: 0 auto;
}
.login-form-button {
  width: 100%;
  margin-bottom: 10px;
  background-color: var(--vt-c-app_theme);
  border-color: var(--vt-c-app_theme);
  color: var(--vt-c-white);
}
.login-form-button:hover {
  background-color: color-mix(in srgb, var(--vt-c-app_theme) 80%, white);
  border-color: color-mix(in srgb, var(--vt-c-app_theme) 80%, white);
  color: var(--vt-c-black); /* 悬停时字体颜色变为黑色 */
}
.login-form-button:active {
  background-color: color-mix(in srgb, var(--vt-c-app_theme) 90%, black);
  border-color: color-mix(in srgb, var(--vt-c-app_theme) 90%, black);
  color: var(--vt-c-white); /* 点击时字体颜色保持白色 */
}
.password-strength {
  margin-top: 10px;
  font-size: 0.9em;
}
.back-button {
  margin-top: 10px;
  border-color: var(--vt-c-white);
  transition: color 0.3s, border-color 0.3s;
}

.back-button:hover {
  color: var(--vt-c-app_theme);
  border-color: var(--vt-c-app_theme);
}

.or-text {
  color: var(--vt-c-white);
  margin: 0 8px;
}
.toggle-mode-link {
  color: var(--vt-c-app_theme);
  cursor: pointer;
  text-decoration: none;
}
.toggle-mode-link:hover {
  text-decoration: underline;
}
.email-code-container {
  display: flex;
  align-items: center;
}
.email-code-container .ant-input {
  flex: 1;
  padding: 10px; /* 设置输入框的内边距 */
}
.send-email-code-button {
  margin-left: 10px;
  white-space: nowrap;
  background-color: var(--vt-c-app_theme);
  border-color: var(--vt-c-app_theme);
  color: var(--vt-c-white);
}

.send-email-code-button:hover {
  background-color: color-mix(in srgb, var(--vt-c-app_theme) 80%, white);
  border-color: color-mix(in srgb, var(--vt-c-app_theme) 80%, white);
  color: var(--vt-c-black); /* 悬停时字体颜色变为黑色 */
}

.send-email-code-button:active {
  background-color: color-mix(in srgb, var(--vt-c-app_theme) 90%, black);
  border-color: color-mix(in srgb, var(--vt-c-app_theme) 90%, black);
  color: var(--vt-c-white); /* 点击时字体颜色保持白色 */
}
</style>
