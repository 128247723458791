<script setup lang="ts">
  import { NCarousel } from 'naive-ui';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { shootImagesData } from '../utils';
  import { ArrowBack, ArrowForward } from '@vicons/ionicons5'
import i18next from 'i18next';

  const images = ref<string[]>([]);
  const innerWidth = ref(window.innerWidth);
  const carouselRef = ref<any>(null);

  onMounted(() => {
    images.value = shootImagesData();
    window.addEventListener('resize', updateInnerWidth);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', updateInnerWidth);
  });

  const updateInnerWidth = () => {
    innerWidth.value = window.innerWidth;
  };
  const slidesPerView = computed(() => {
    if (innerWidth.value < 768) {
      return 2;
    }
    return 4;
  });

  const clickNext = () => {
    if (carouselRef.value) {
      const totalSlides = images.value.length;
      const  currentIndex = carouselRef.value.getCurrentIndex();
      let nextIndex = currentIndex + 4;
      if (nextIndex >= totalSlides) {
        nextIndex = 0;
      }
      carouselRef.value.to(nextIndex);
    }
  };

  const clickPrev = () => {
    if (carouselRef.value) {
      const totalSlides = images.value.length;
      const  currentIndex = carouselRef.value.getCurrentIndex();
      let preIndex = currentIndex - 4;
      if (preIndex < 0) {
        preIndex = totalSlides - 4;
      }
      carouselRef.value.to(preIndex);
    }
  };

</script>
<template>
  <div class="container-shoot">
    <div class="app-desc">
      <h1>
        {{ JSON.parse(i18next.t('downloadPage')).learnMore }}
        </h1>
      <p> 
        {{ JSON.parse(i18next.t('downloadPage')).learnMoreDesc }}
      </p>
    </div>
    <NCarousel 
      ref="carouselRef"
      :slides-per-view="slidesPerView" 
      draggable 
      show-arrow
      :show-dots="false"
      class="carousel"
    >
      <div v-for="image in images">
        <img
          class="carousel-img"
          :src="image"
        >
      </div>
      <template #arrow="{ prev, next, to }">
        <div class="custom-arrow">
          <button type="button" class="custom-arrow--left" @click="clickPrev">
            <n-icon><ArrowBack /></n-icon>
            <ArrowBack />
          </button>
          <button type="button" class="custom-arrow--right" @click="clickNext">
            <ArrowForward />
          </button>
        </div>
      </template>
    </NCarousel>
  </div>
</template>

<style scoped>
.container-shoot {
  flex-direction: column;
  border-bottom: 1px solid #26272B;
  padding-bottom: 75px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.app-desc {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  color: white;
  max-width: 90vw;
}
.carousel {
    max-width: 1320px;
    width: 85vw;
  }
.carousel-img {
  object-fit:cover;
  width: 40vw;
  aspect-ratio: 0.54;
  border-radius: 16px;
}
.custom-arrow {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.custom-arrow button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 12px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 0;
  border-radius: 8px;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.custom-arrow button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-arrow button:active {
  transform: scale(0.95);
  transform-origin: center;
}
@media (min-width: 768px) {
  .carousel {
    max-width: 1320px;
    width: 60rem;
  }
  .carousel-img {
    object-fit: cover;
    width: 14rem;
  }
  .app-desc {
    max-width: 60vw;
  }
}
</style>