import { createRouter, createWebHistory } from 'vue-router'
import MurDownApp from '@/pages/DownApp/MurDownApp.vue'
// import AboutView from '@/pages/AboutView.vue'
import PrivacyPolicy from '../pages/PrivacyPolicy.vue'
import "../../env.d.ts";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'downApp',
      component: MurDownApp
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/Home/HomePage.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacyPolicy',
      component: () => import('@/pages/PrivacyPolicy.vue')
    },
    {
      path: '/chat/:promptId',
      name: 'chat',
      component: () => import('@/pages/Chat/ChatPage.vue'),
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/pages/AboutView.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/pages/Settings/Settings.vue')
    },
    {
      path: '/privacy-policy-en',
      name: 'privacyPolicyEn',
      redirect: '/privacy-policy-en.html'
    },
    {
      path: '/privacy-policy-zh',
      name: 'privacyPolicyZh',
      redirect: '/privacy-policy-zh.html'
    },
    // 添加新的删除账号路由
    {
      path: '/delete-account',
      name: 'deleteAccount',
      component: () => import('@/pages/Account/DeleteAccount.vue')
    }
  ]
})

export default router
