<script setup lang="ts">
import { Button, Modal, message, Spin } from 'ant-design-vue';
import { onDeactivated, ref, computed, onMounted, watch } from 'vue'
import { initGoogleClient } from './utils';
import EmailSignin from './EmailSignin.vue';
import i18next from 'i18next';

const props = defineProps<{
  visible: boolean
  closeModal: () => void
  onLoginSuccess: () => void
}>()

// const emit = defineEmits(['close', 'login-success'])

const showEmailSignin = ref(false);
const locale = ref(i18next.language);

onMounted(() => {
  i18next.on('languageChanged', () => {
    locale.value = i18next.language;
  })
})

const handleSignin = () => {
  initGoogleClient(() => {
   signinSuccess();
  }, () => {
    message.error(JSON.parse(i18next.t('signInPage')).operationFailed);
    // message.error('登录失败');
  }).requestAccessToken()
}

const signinSuccess = () => {
  message.success(JSON.parse(i18next.t('signInPage')).loginSuccess);
  // emit('login-success');
  resetState();
  props.onLoginSuccess();
}

const handleEmailSignin = () => {
  showEmailSignin.value = true;
}

const resetState = () => {
  showEmailSignin.value = false;
  // 如果有其他需要重置的状态，也可以在这里添加
}

const handleCloseModal = () => {
  resetState();
  props.closeModal();
}

onDeactivated(() => {
  console.log('deactivated');
})

const termsOfServiceUrl = computed(() => {
  return locale.value === 'zh' ? '/terms-of-servies-zh.html' : '/terms-of-servies-en.html';
});

const privacyPolicyUrl = computed(() => {
  return locale.value === 'zh' ? '/privacy-policy-zh.html' : '/privacy-policy-en.html';
});

</script>

<template>
  <Modal 
    :open="visible" 
    :title="JSON.parse(i18next.t('signInPage')).signIn"
    @cancel="handleCloseModal"
    :footer="null"
    :bodyStyle="{
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
    }"
    class="signin-modal"
  >
    <Button 
      v-if="!showEmailSignin"
      @click="handleSignin"
      class="signin_button"
    >
      {{ JSON.parse(i18next.t('signInPage')).signInWithGoogle }}
    </Button>
    <Button 
      v-if="!showEmailSignin"
      @click="handleEmailSignin"
      class="signin_button"
    >
      {{ JSON.parse(i18next.t('signInPage')).signInWithEmail }}
    </Button>
    
    <!-- 修改协议文本部分 -->
    <p class="agreement">
      {{ JSON.parse(i18next.t('signInPage')).agreement }}
      <a :href="termsOfServiceUrl" target="_blank" class="link">
        {{ JSON.parse(i18next.t('signInPage')).termsOfServices }}
      </a> 
      {{ JSON.parse(i18next.t('signInPage')).and }}
      <a :href="privacyPolicyUrl" target="_blank" class="link">
        {{ JSON.parse(i18next.t('signInPage')).privacyPolicy }}
      </a>.
    </p>

    <EmailSignin v-if="showEmailSignin" @login-success="signinSuccess" @back="showEmailSignin = false" />
  </Modal>
</template>

<style>
.signin-modal .ant-modal-content {
  background-color: var(--vt-c-black-item);
}

.signin-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: 1px solid var(--vt-c-divider-dark-1);
}

.signin-modal .ant-modal-title {
  color: var(--vt-c-text-dark-1);
}

.signin-modal .ant-modal-close {
  color: var(--vt-c-text-dark-2);
}
</style>

<style scoped>
.signin_button {
  background-color: var(--vt-c-app_theme);
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.agreement {
  color: var(--vt-c-white); /* 设置协议文本颜色为白色 */
  font-size: 14px; /* 设置字体大小 */
  margin-top: 10px; /* 添加上边距 */
  text-align: center; /* 居中对齐 */
}

.link {
  color: var(--vt-c-app_theme); /* 设置链接颜色为主题色 */
  text-decoration: underline; /* 添加下划线 */
}

.link:hover {
  background-color: var(--vt-c-app_theme);
  color: var(--vt-c-white);
}
</style>
