import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'
import downloadI18n from '../pages/DownApp/utils/i18n.json'
import settingI18n from '../pages/Settings/locales.json'
import signInI18n from '../components/signin/utils/locales.json'
import errorCodeI18n from '../request/errorCode.json'
import deleteAccountI18n from '../pages/Account/locales.json'

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          home: 'Home',
          contactUs: 'Contact Us',
          downloadAPP: 'Download APP',
          downloadPage: JSON.stringify(downloadI18n.en),
          settingPage: JSON.stringify(settingI18n.en),
          signInPage: JSON.stringify(signInI18n.en),
          errorCode: JSON.stringify(errorCodeI18n.en),
          deleteAccount: JSON.stringify(deleteAccountI18n.en)
        }
      },
      zh: {
        translation: {
          home: '首页',
          contactUs: '联系我们',
          downloadAPP: '下载APP',
          downloadPage: JSON.stringify(downloadI18n.zh),
          settingPage: JSON.stringify(settingI18n.zh),
          signInPage: JSON.stringify(signInI18n.zh),
          errorCode: JSON.stringify(errorCodeI18n.zh),
          deleteAccount: JSON.stringify(deleteAccountI18n.zh)
        }
      }
    }
  });

export default function (app: any) {
  app.use(I18NextVue, { i18next })
  return app
}

export const useMurI18n = (key: string) => {
  return JSON.parse(i18next.t(key))
}