<script setup lang="ts">
import i18next from 'i18next';
import CopyRightInfo from './views/CopyRightInfo.vue';
import LocalDescription from './views/LocalDescription.vue';
import MurDownLoadBanner from './views/MurDownLoadBanner.vue';
import PrivacyPolicyDescription from './views/PrivacyPolicyDescription.vue';
import ShootScreen from './views/ShootScreen.vue';
import { useMurI18n } from '@/common/i18n';
import { computed, onMounted, onUnmounted, onUpdated, ref } from 'vue';
import { getBannerData } from './utils';

const bannerData = ref(getBannerData());

onMounted(() => {
  i18next.on('languageChanged', updateBannerData);
});

const updateBannerData = () => {
  bannerData.value = getBannerData();
};

onUnmounted(() => {
  i18next.off('languageChanged', updateBannerData);
});

</script>

<template>
  <div class="custom-width">
    <MurDownLoadBanner :bannerData="bannerData" />
    <!-- <MurDownloadCard /> -->
    <ShootScreen />
    <LocalDescription />
    <PrivacyPolicyDescription />
    <CopyRightInfo />
  </div>
</template>

<style scoped>

.custom-width {
  width: 100%;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  justify-content: center;
}
.card {
  background-color: var(--vt-c-app_theme);
  border-radius: 10px;
}
.card_content {
  align-items: center;
}
.buttons-container {
  display: flex; /* 使按钮横向排列 */
  justify-content: center;
}
.button {
  width: 150px;
  height: 50px; /* 设置高度，确保与宽度相等 */
  display: flex;
  background-color: transparent;
  color: var(--vt-c-white);
  border: 1px solid var(--vt-c-white);
  border-radius: 25px;
  padding: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.launch {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;

}

@media screen and (min-width: 768px){
  .card {
  height: 60vh; /* 或者使用max-height */
  width: 768px;
  margin-top: 2rem;
  }
  .card_content {
    display: flex;
    height: 100%;
  }
  .app_info {
    width: 60%;
  }
  .buttons-container {
    justify-content: space-between; /* 使按钮平均分布 */
  }
  .launch {
    width: 150px;
    margin-left: 80px;
  }
}

footer {
  margin-top: 20px;
  text-align: center;
}

footer a {
  color: #666;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
</style>
