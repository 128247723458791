<script setup lang="ts">
import router from '@/router';
import i18next from 'i18next';

const clickedTerm = () => {
  window.open('/terms-of-servies-en.html', '_blank');
};

const openPrivacyPolicy = () => {
  // window.open('/privacy-policy', '_blank');
  console.log("router.push-/privacy-policy");
  // router.push('/privacypolicy');
  //https://www.murmured.me/privacy-policy-en.html
  window.open('/privacy-policy-en.html', '_blank');
};
</script>

<template>
  <div class="container-copyright">
      <div style="flex-direction: row; color: var(--vt-c-white);">
        <button class="button" @click="clickedTerm" >
          {{ JSON.parse(i18next.t('downloadPage')).userTerms }}
        </button>
        |
        <button class="button" @click="openPrivacyPolicy">
          {{ JSON.parse(i18next.t('downloadPage')).userPrivacy }}
        </button>
      </div>
      <p class="copyright">
        {{ JSON.parse(i18next.t('downloadPage')).copyRight }}
      </p>
    </div>
</template>

<style scoped>
.container-copyright {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  background-color: var(--vt-c-black-item);
}

.container .button {
  color: var(--vt-c-white);
  background-color: transparent;
  border: none;
  font-size: 13px;
  cursor: pointer; /* 添加光标样式 */
}

/* 添加 hover 样式 */
.container .button:hover {
  color: var(--vt-c-black-item); /* hover 时的文字颜色 */
  background-color: var(--vt-c-white); /* hover 时的背景颜色 */
}

.copyright {
  color: var(--vt-c-white);
  font-size: 12px;
  margin-top: 2rem;
  margin-left: 1rem;
  width: 95vw;
  text-align: center;
}
</style>
