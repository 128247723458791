<script setup lang="ts">
  import i18next from 'i18next';
import { NCarousel } from 'naive-ui';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  const innerWidth = ref(window.innerWidth);
  onMounted(() => {
    window.addEventListener('resize', updateInnerWidth);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', updateInnerWidth);
  });

  const updateInnerWidth = () => {
    innerWidth.value = window.innerWidth;
  };

</script>
<template>
  <div class="container-privacy">
    <div class="app-desc">
      <h1>
        {{ JSON.parse(i18next.t('downloadPage')).privacy }}
      </h1>
      <p>
        {{ JSON.parse(i18next.t('downloadPage')).privacyDesc }}
      </p>
    </div>
    <img
      class="carousel-img"
      src="@/assets/download/app_info.png"
    />
  </div>
</template>

<style scoped>
.container-privacy {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #26272B;
  padding-bottom: 75px;
  justify-content: center;
  align-items: center;
  /* padding-left: 200px; */
  /* padding-right: 200px; */
}
.app-desc {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  color: white;
  max-width: 90vw;
}
.carousel-img {
  object-fit: cover;
  width: 80vw;
}
@media screen and (min-width: 768px) {
  .carousel-img {
    width: 27.76rem;
  }
  .app-desc {
  max-width: 50vw;
}
  
}
</style>