<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { NAvatar } from 'naive-ui';
import i18next from 'i18next';

  const innerWidth = ref(window.innerWidth);
  onMounted(() => {
    window.addEventListener('resize', updateInnerWidth);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', updateInnerWidth);
  });

  const updateInnerWidth = () => {
    innerWidth.value = window.innerWidth;
  };
  const dynamicStyle = computed(() => {
    if (innerWidth.value < 768) {
      return {
        width: '100px',
      };
    }
    const width = (288 * 4 + 23 * 3) * innerWidth.value / 1920;
    return {
      width: `${width}px`,
    };
  });

  const dynamicImageStyle = computed(() => {
    if (innerWidth.value < 768) {
      return {
        width: '100px',
      };
    }
    const width = 288 * innerWidth.value / 1920;
    return {
      width: `${width}px`,
    };
  });
</script>
<template>
  <div class="container-local">
    <div class="app-desc">
      <h1>
        {{ JSON.parse(i18next.t('downloadPage')).muiltiLanguage }}
      </h1>
      <p>{{ JSON.parse(i18next.t('downloadPage')).muiltiLanguageDesc }}</p>
    </div>
    <div class="left-chat">
      <img
        width="48"
        height="48"
        style="z-index: 999;"
        src="@/assets/download/heart_sister.png"
      />
      <div class="chat-view left">
        <div class="chat-name">知心姐姐</div>
        <div class="chat-content">I am Xing'er, your virtual girlfriend. I can chat with you</div>
      </div>
    </div>
    <div class="right-chat">
      <div class="chat-view right">
        <div class="chat-name">素颜男神</div>
        <div class="chat-content right">Hello, how are you today</div>
      </div>
      <img
        width="48"
        height="48"
        style="z-index: 999;"
        src="@/assets/download/young_boy.png"
      />
    </div>
    <div class="left-chat">
      <img
        width="48"
        height="48"
        style="z-index: 999;"
        src="@/assets/download/heart_sister.png"
      />
      <div class="chat-view left">
        <div class="chat-name">知心姐姐</div>
        <div class="chat-content">I am Xing'er, your virtual girlfriend. I can chat with you</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-local {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-bottom: 1px solid #26272B;
  padding-bottom: 75px;
  justify-content: center;
  align-items: center;
}
.app-desc {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  color: white;
  max-width: 90vw;
}
.left-chat {
  display: flex;
  flex: 1;
  margin-top: 50px;
  align-items: center;
  width: 90%;
  align-self: flex-start;
  max-width: 490px;
}
.chat-view {
  background-color: #1A1B1E;
  border-radius: 16px;
  padding-left: 29px;
  padding-right: 29px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
}
.left-chat .left {
  margin-left: -12px;
}
.chat-name { 
  font-size: 14px;
  color: white;
  opacity: 0.6;
}
.chat-content {
  font-size: 16px;
  color: white;
}
.right-chat {
  display: flex;
  flex: 1;
  margin-top: 50px;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  width: 90%;
  max-width: 490px;
}
.right-chat .right {
  margin-right: -12px;
}
@media screen and (min-width: 768px) {
  .left-chat {
    align-self: center;
    margin-left: -7.8rem;
    width: 490px
  }
  .right-chat {
    align-self: center;
    margin-left: 18rem;
    width: 490px;
  }
  .app-desc {
    max-width: 50rem;
  }
  
}
</style>