
import axios from 'axios';
import i18next from 'i18next';
import Cookies from 'js-cookie';

const TIME_OUT = 1 * 60 * 1000 // min

const userAccount = Cookies.get('user_auth_account');
var userToken = '';
if (userAccount && userAccount.length > 0) {
  const userAccontObj = JSON.parse(userAccount);
  if (userAccontObj?.encodedToken?.length > 0) {
    userToken = userAccontObj.encodedToken;
  }
}

export const axiosInstanceJava = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL_JAVA,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${userToken}`,
    platform: 'web',
  },
})


export const axiosInstanceNode = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL_NODE,
  // timeout: 10000,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
    // platform: 'web',
  },
})

export function setRequestAuthorization(token: string) {
  axiosInstanceNode.defaults.headers.Authorization = `Bearer ${token}`
  axiosInstanceJava.defaults.headers.Authorization = `Bearer ${token}`
  // nodeApi?.axiosInstance?.defaults.headers.Authorization = `Bearer ${token}`
}

export async function getRequestWithJavaHost(url: string) {
  try{
    const response = await axiosInstanceJava.get(url)
    const { data } = response
    if (data?.code === 0) {
      return Promise.resolve(data.result)
    } else {
      return Promise.reject(new Error(data?.message || `Request failed with code ${data.code}`))
    }
  } catch (error: any) {
    console.log('getRequestWithJavaHost:', error)
    return Promise.reject(error.message || 'network error')
  }
}

export async function fetchPostWthJavaHost(url: string, params: any) {
  try {
    const response = await axiosInstanceJava.post(url, params)
    console.log('fetchPostWthJavaHostresponse:', response)
    const {data} = response
    
    if (data?.code === 0) {
      return Promise.resolve(data.result);
    } else {
      const errorMessage = JSON.parse(i18next.t('errorCode'))[data?.code] || data?.message || `Request failed with code ${data.code}`
      return Promise.reject(errorMessage);
    }
  } catch (error: any) {
    console.log('postRequestWithJavaHost Error:', error)
    return Promise.reject(error.message || 'network error')
  }
}

export async function fetchPostWthNodeHost(url: string, params: any) {
  try {
    console.log('fetch post request with node host:', axiosInstanceNode.defaults)
    const response = await axiosInstanceNode.post(url, params)
    const {data, status} = response
    
    if (status === 200) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(new Error(data?.message || `Request failed with code ${status}`));
    }
  } catch (error: any) {
    console.log('postRequestWithNodeHost:', error)
    return Promise.reject(error.message || 'network error')
  }
}

export async function getRequestWithNodeHost(url: string) {
  try{
    const response = await axiosInstanceNode.get(url)
    const { data, status } = response
    if (status === 200) {
      return Promise.resolve(data)
    } else {
      return Promise.reject(new Error(data?.message || `Request failed with code ${status}`))
    }
  } catch (error: any) {
    console.log('getRequestWithNodeHost:', error)
    return Promise.reject(error.message || 'network error')
  }
}