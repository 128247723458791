import { getUserID } from "@/common/utils";
import { fetchPostWthJavaHost, fetchPostWthNodeHost, getRequestWithNodeHost, setRequestAuthorization } from "@/request";
import Cookies from 'js-cookie';

export function initGoogleClient (onSuccess: any, onFailure: any) {
  console.log('(window as any).google:', (window as any).google)
  if (!(window as any).google) {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = () => {
      console.log('google api loaded')
    }
    document.body.appendChild(script)
  }
  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  if (!clientId) {
    throw new Error('Google Client ID is not defined in environment variables');
  }
  return (window as any).google?.accounts.oauth2.initTokenClient({
    client_id: clientId,
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    callback: async (response: any) => {
      try {
        console.log('response', response)
        const googleUserInfo = await getGoogleUserInfo(response.access_token)
        if (googleUserInfo) {
          const { name, email, picture, id } = googleUserInfo
          const userInfo = {
            name: name || '',
            email: email || '',
            image: picture || '',
            providerAccountId: id || '',
            access_token: response.access_token || '',
          }
          const res = await getUserInfoFromBiz(userInfo)
          if (res) {
            console.log('sign in success', res)
            onSuccess()
            return;
          }
          
          onFailure()
        }
      } catch (error) {
        console.log('sign in error')
        onFailure()
      }
    },
  });
}

export async function getGoogleUserInfo(token: string) {
  try {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`
    const response = await fetch(url, {
      method: 'GET',
    })
    return response.json()
  } catch (error) {
    throw new Error('Failed to get user info')
  }
}

// {
//   "encodedToken": "",
//   "finishedOnboarding": true,
//   "userId": "OmDyQpg9OSzuX8b15SDR8"
// }
export async function getauthAccountForBiz(params: any) {
  try {
    const url = 'auth/accounts'
    const requestBody = {
      ...params,
      type: 'oauth',
      provider: 'google',
      token_type: 'Bearer',
    }
    const authAccount = await fetchPostWthNodeHost(url, requestBody)
    saveUserAcount(authAccount)

  } catch (error) {
    console.log('getUserTokenForBiz:', error)
    return Promise.reject(error)}
}

function saveUserAcount(userAccount: any) {
  if (!userAccount) {
    return
  }
  const { encodedToken } = userAccount
  setRequestAuthorization(encodedToken)
  Cookies.set('user_auth_account', JSON.stringify(userAccount), { expires: 7, path: '/' });
}

// {
//   "createdAt": "2024-06-21T06:56:39.471Z",
//   "email": "",
//   "followedCount": 0,
//   "followingCount": 0,
//   "id": "",
//   "image": "",
//   "name": "",
//   "nsfw": false,
//   "promptUsageSum": 0,
//   "role": "USER"
// }
export async function getUserProfile() {
  try {
    const userId = getUserID()
    const url = `user/profile?id=${userId}`
    const userProfile = await getRequestWithNodeHost(url)
    console.log('userProfile:', userProfile)
    localStorage.setItem('user_profile', JSON.stringify(userProfile))
    return Promise.resolve(userProfile)

  } catch (error) {
    console.log('getUserProfile:', error)
    return Promise.reject(error)
  }
}

export async function getUserInfoFromBiz(userInfo: any) {
  try {
    saveUserFromGoogle(userInfo)
    await getauthAccountForBiz(userInfo)
    const userProfile = await getUserProfile()
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export function saveUserFromGoogle(userInfo: any) {
  if (!userInfo) {
    return
  }
  localStorage.setItem('user_info_from_google', JSON.stringify(userInfo))
}

export function saveUserFromBiz(userInfo: any) {
  if (!userInfo) {
    return
  }
  const now = new Date();
  // 设置7天的有效期
  const expirationDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
  const userInfoWithExpiry = {
    ...userInfo,
    expiry: expirationDate.getTime(),
  };
}

export interface PasswordStrengthReturn {
  value: string;
  color: string;
  valid: boolean;
}

export function checkPasswordStrength(password: string): PasswordStrengthReturn {
  const lengthRegex = /.{8,}/; // 密码长度至少8个字符
  const lowercaseRegex = /[a-z]/; // 至少一个小写字母
  const uppercaseRegex = /[A-Z]/; // 至少一个大写字母
  const numberRegex = /[0-9]/; // 至少一个数字
  const specialCharRegex = /[!@#$%^&*]/; // 至少一个特殊字符

  const strengthPoints = 
    +lengthRegex.test(password) +
    +lowercaseRegex.test(password) +
    +uppercaseRegex.test(password) +
    +numberRegex.test(password) +
    +specialCharRegex.test(password);

  switch (strengthPoints) {
    case 5:
      return {
        value: "pswVeryStrong",
        color: "green",
        valid: true,
      }
    case 4:
      return {
        value: "pswStrong",
        color: "green",
        valid: true,
      }
    case 3:
      return {
        value: "pswMedium",
        color: "orange",
        valid: true,
      };
    case 2:
      return {
        value: "pswWeak",
        color: "red",
        valid: false,
      };
    default:
      return {
        value: "pswVeryWeak",
        color: "red",
        valid: false,
      };
  }
}

export async function loginWithEmail(email: string, password: string) {
  try {
    const url = 'oauth/v1/login'
    const requestBody = {
      email,
      password,
    }
    const userAccount = await fetchPostWthJavaHost(url, requestBody)
    console.log('loginWithEmail:', userAccount)
    saveUserAcount(userAccount)
    // 获取用户资料
    await getUserProfile();
    return true;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

export async function registerWithEmail(nickname: string, email: string, emailCode: string, password: string) {
  try {
    const url = 'oauth/v1/register';
    const requestBody = {
      name: nickname,
      email,
      verifyCode: emailCode,
      password,
    };
    const result = await fetchPostWthJavaHost(url, requestBody);
    console.log('registerWithEmail:', result);
    return true;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
}

export async function fetchEmailCode(email: string, nickName: string) {
  const url = 'oauth/v1/send-verification-code'
  try {
    console.log('fetchEmailCode:', email, nickName)
    return fetchPostWthJavaHost(url, { 
      email,
      name: nickName,
      type: 'email',
    })
  } catch {
    return Promise.reject('sendVerificationCodeWothEmail error')
  }
}

