<template>
  <a-button 
    v-if="!isLoggedIn"
    @click="handleSignin"
    type="primary"
    size="small"
    :style="{ backgroundColor: 'var(--vt-c-app_theme)' }"
  >
    {{ buttonText }}
  </a-button>
  <a-dropdown v-else :trigger="['hover']" overlayClassName="custom-user-dropdown">
    <div class="user-info">
      <a-avatar 
        :src="userAvatar"
        :size="32"
      />
      <span class="user-name">{{ userName }}</span>
    </div>
    <template #overlay>
      <a-menu class="custom-user-menu">
        <a-menu-item key="1" @click="handleSettings">
          {{ currentLanguage === 'en' ? 'Settings' : '设置' }}
        </a-menu-item>
        <a-menu-item key="2" @click="handleLogout">
          {{ currentLanguage === 'en' ? 'Logout' : '退出登录' }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <SigninModel 
    :visible="showSigninModal"
    :closeModal="closeModal"
    :onLoginSuccess="handleLoginSuccess"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { Button as AButton, Avatar as AAvatar, Dropdown, Menu } from 'ant-design-vue';
import i18next from 'i18next';
import SigninModel from './SigninModel.vue';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { getUserProfile } from './utils';
import { useUserStore } from '@/stores/userStore';

const { 
  setLoggedIn, setUserAvatar, setUserName, logout,
  setAccessToken, setUserId, setShowSigninModal
} = useUserStore();

const userStore = useUserStore();
const router = useRouter();
const currentLanguage = ref(i18next.resolvedLanguage);

const isLoggedIn = computed(() => userStore.isLoggedIn);
const userAvatar = computed(() => userStore.userAvatar);
const userName = computed(() => userStore.userName);
const showSigninModal = computed(() => userStore.showSigninModal);

onMounted(async () => {
  console.log('onMounted userinfo');
  const userAccount = Cookies.get('user_auth_account');
  console.log('userAccount', userAccount);
  if (userAccount && userAccount.length > 0) {
    const userAccontObj = JSON.parse(userAccount);
    const { encodedToken, userId } = userAccontObj;
    if (encodedToken?.length > 0) {
      setLoggedIn(true);
      setAccessToken(encodedToken);
      setUserId(userId);
      const userProfile = await getUserProfile()
      if (userProfile) {
        setUserAvatar(userProfile.image);
        setUserName(userProfile.name || userProfile.username);
      }
    }
  }
  i18next.on('languageChanged', (lng) => {
    currentLanguage.value = lng;
  });
})

const buttonText = computed(() => {
  return currentLanguage.value === 'en' ? 'Sign In/ Sign Up' : '登录/注册';
});

const handleSignin = () => {
  console.log('handleSignin');
  setShowSigninModal(true);
};

const handleLoginSuccess = () => {
  setShowSigninModal(false);
  setLoggedIn(true);
  const userInfo = localStorage.getItem('user_profile');
  if (userInfo) {
    const parsedUserInfo = JSON.parse(userInfo);
    setUserAvatar(parsedUserInfo.image);
    setUserName(parsedUserInfo.name || parsedUserInfo.username);
  }
};

const closeModal = () => {
  setShowSigninModal(false);
};

const handleAvatarClick = () => {
  // 处理头像点击事件，例如显示用户菜单
  console.log('Avatar clicked');
};

const handleSettings = () => {
  router.push('/settings');
};

const handleLogout = () => {
  // 处理退出登录的逻辑
  logout();
  Cookies.remove('user_auth_account');
  localStorage.removeItem('user_profile');
  console.log('User logged out');
  router.push('/');
};

</script>

<style scoped>
.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-name {
  display: block;
  margin-left: 5px;
  font-size: 12px;
  color: var(--vt-c-white);
}
</style>
